
import VueRecaptcha from "vue-recaptcha";
import "filepond/dist/filepond.min.css";
import FormValidator from "@/mixins/FormValidator";
import vueFilePond, { setOptions } from "vue-filepond";
import { Component, Mixins } from "vue-property-decorator";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

const FilePond = vueFilePond(
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

interface ticketData {
  street: string;
  street_number: string;
  hint: string;
  message: string;
}

interface respuesta {
  id_pregunta: number | null;
  id_formulario: number | null;
  pregunta: string | null;
  respuesta: string | null;
  tipo: number | null;
  obligatorio: number | null;
}

interface dataRespuesta {
  id_formulario: number | null;
  preguntas: Array<respuesta>;
}

@Component({
  components: {
    VueRecaptcha
  }
})
export default class SendTicketForm extends Mixins(FormValidator) {
  street_loading = false;
  street_search_empty = false;
  disable_button = false;

  private formulario: any = null;
  private respuestas: Array<dataRespuesta> = [];
  private data_preguntas: any = [];
  id_formulario = 0;
  mensaje = "";

  requiere_documento = false;
  mensaje_documento = "";

  protected formData: ticketData = {
    street: "",
    street_number: "",
    hint: "",
    message: ""
  };

  protected captcha_key = "";
  private beforeMount() {
    this.captcha_key = process.env.VUE_APP_KEY_CAPTCHA as string;
  }

  private onVerify(response: any) {
    this.sendTicketAttempt();
  }

  private onExpired() {
    this.$q.notify({
      color: "negative",
      message: "Por favor, recargue la página.",
      icon: "report_problem",
      position: "top",
      timeout: 2500
    });
  }

  private onError() {
    this.$q.notify({
      color: "negative",
      message: "Ha ocurrido un error, intente más tarde.",
      icon: "report_problem",
      position: "top",
      timeout: 2500
    });
  }

  private mounted() {
    this.getFormulario();
    this.getServicioMeta();
    this.getDocumentMessage();
  }

  private getDocumentMessage() {
    this.mensaje_documento = "";
    this.requiere_documento = false;
    this.$axios
      .post("servicios/requiere/documento", {
        id_servicio: Number(this.$store.state.ticket.service.id)
      })
      .then(response => {
        if (response.data) {
          if (response.data.mensaje && response.data.mensaje != "") {
            this.mensaje_documento = response.data.mensaje;
            this.requiere_documento = true;
          }
        }
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "accent",
          position: "top",
          timeout: 5000
        });
      });
  }

  private areAllFilesOK(): boolean {
    let files = (this.$refs.pond as any).getFiles();
    for (let i = 0; i < files.length; i++) {
      if (files[i].status != 5) {
        return false;
      }
    }
    return true;
  }

  private triggerFilepond() {
    (document.getElementsByClassName(
      "filepond--browser"
    )[0] as HTMLInputElement).click();
  }

  private typeDetection(file: any, type: any) {
    let checkExtensions = ["doc", "docx", "xls", "xlsx", "ppt", "pptx"];
    let isAllowed = new Promise((resolve, reject) => {
      let extension = file.name
        .split(".")
        .pop()
        .toLowerCase();
      if (checkExtensions.indexOf(extension) > -1) {
        let officeType = "";
        switch (extension) {
          case "doc":
          case "docx":
            officeType = "application/doc";
            break;
          case "xls":
          case "xlsx":
            officeType = "application/xls";
            break;
          case "ppt":
          case "pptx":
            officeType = "application/ppt";
            break;
          default:
            officeType = "";
            break;
        }
        if (officeType === "") {
          reject();
        } else {
          resolve(officeType);
        }
      } else {
        resolve(type);
      }
    });
    return isAllowed;
  }

  private filepondServer = {
    url: process.env.VUE_APP_API_PATH + "/temporal_archivos/",
    process: {
      url: "upload",
      method: "PUT",
      withCredentials: false,
      onload: (response: string) => {
        let json_response = JSON.parse(response);
        return json_response.response.nombre;
      },
      onerror: (response: any) => response.data,
      ondata: (formData: any) => {
        return formData;
      }
    },
    revert: "delete",
    restore: null,
    load: null,
    fetch: null
  };

  private getFormulario() {
    this.$axios
      .post("formularios/servicio", {
        id_servicio: Number(this.$store.state.ticket.date_ticket.service_id)
      })
      .then(response => {
        if (response.data && response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            const pregunta = response.data[i];
            this.data_preguntas.push({
              id_pregunta: pregunta.id,
              id_formulario: pregunta.id_formulario,
              pregunta: pregunta.pregunta,
              respuesta: "",
              opciones: pregunta.opciones,
              tipo: pregunta.tipo,
              obligatorio: pregunta.obligatorio,
              icon: pregunta.icon,
              label: pregunta.label
            });
          }
        }
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "accent",
          position: "top",
          timeout: 5000
        });
      });
  }

  private getServicioMeta() {
    this.$axios
      .post("servicio_metas/detalle", {
        id_servicio: Number(this.$store.state.ticket.date_ticket.service_id)
      })
      .then(response => {
        this.mensaje = response.data;
      })
      .catch(error => {
        this.$q.notify({
          message: "Ha ocurrido un error",
          color: "accent",
          position: "top",
          timeout: 5000
        });
      });
  }

  private sendTicketAttempt() {
    this.disable_button = true;

    if (!this.areAllFilesOK()) {
      this.$q.notify({
        message: this.$t("notification.problem_with_files") as string,
        color: "accent",
        position: "top",
        timeout: 7500
      });
      return (this.disable_button = false);
    }

    let captcha = (window as any).grecaptcha.getResponse();
    if (captcha == "") {
      this.$q.notify({
        message: this.$t("notification.check_captcha") as string,
        color: "accent",
        position: "top",
        timeout: 7500
      });
      return (this.disable_button = false);
    }

    //ordenar input de archivos filepond)
    let adjuntos_input = document.getElementsByName("Files");
    //backend no soporta vacio, debe ser null
    let adjuntos_empty = true;
    let adjuntos: any = [];
    for (let i = 0; i < adjuntos_input.length; i++) {
      adjuntos[i] = (adjuntos_input[i] as HTMLInputElement).value;
    }
    for (let j = 0; j < adjuntos.length; j++) {
      if (adjuntos[j] !== "") {
        adjuntos_empty = false;
      }
    }
    if (adjuntos_empty) {
      adjuntos = null;
    }

    var flag = true;
    if (this.data_preguntas && this.data_preguntas.length > 0) {
      for (let i = 0; i < this.data_preguntas.length; i++) {
        if (
          this.data_preguntas[i].obligatorio == 1 &&
          this.data_preguntas[i].respuesta === ""
        ) {
          flag = false;
        }
      }
    }
    if (flag) {
      this.$axios
        .post(
          "tickets/pedir/hora",
          {
            id_servicio: Number(
              this.$store.state.ticket.date_ticket.service_id
            ),
            rut: this.$store.state.logged
              ? this.$store.state.user.rut
              : this.$store.state.ticket.guest_id,
            tipo_documento: this.$store.state.logged
              ? this.$store.state.user.document_type
              : this.$store.state.ticket.guest_document_type,
            adjunto: adjuntos,
            id_horario: Number(this.$store.state.ticket.date_ticket.id),
            message: this.formData.message,
            respuestas: this.data_preguntas
          },
          {
            headers: {
              Authorization: this.$store.state.user.auth_token
                ? this.$store.state.user.auth_token
                : null,
              Captcha: captcha
            }
          }
        )
        .then(response => {
          let ticket = {
            id: response.data.response.id_ticket,
            message: response.data.response.mensaje,
            date: response.data.response.fecha,
            step: 4,
            adjuntos: response.data.response.adjunto
              ? response.data.response.adjunto
              : null,
            qr: response.data.response.qr_code,
            service_name: response.data.service_name
          };
          this.$store.dispatch("submitRequestFinished", ticket);
        })
        .catch(error => {
          let message =
            "Ha ocurrido un error al genera la reserva. \
              Intente nuevamente en otro horario";
          switch (error.response.data.error.message) {
            case "reserva_no_data":
              message =
                "El horario ya no se encuentra disponible. \
                   Intente nuevamente en otro horario";
              break;
            case "servicio_no_data":
              message = "El servicio seleccionado fue inhabilitado";
              break;
            case "persona_no_data":
              message =
                "Ha ocurrido un error. Sus datos como vecino están incompletos";
              break;
            case "save_ticket_error":
              message = "Ha ocurrido un error al crear su solicitud";
              break;
            case "empty_captcha":
              message = "Ha ocurrido un error validando su captcha";
              break;
            case "invalid_captcha":
              message = "Ha ocurrido un error validando su captcha";
              break;
          }
          this.$q.notify({
            message: message,
            color: "accent",
            position: "top",
            timeout: 5000
          });
        })
        .finally(() => {
          this.disable_button = false;
        });
    } else {
      this.disable_button = false;
      this.$q.notify({
        message: "Rellene los campos obligatorios",
        color: "accent",
        position: "top",
        timeout: 5000
      });
    }
  }

  private abortFilterFn() {}
}
