
import { Component, Vue } from "vue-property-decorator";
import SendTicketDateForm from "@/components/forms/SendTicketDateForm.vue";
import FormCard from "@/components/FormCard.vue";

@Component({
  components: {
    SendTicketDateForm,
    FormCard
  }
})
export default class SendRequestDate extends Vue {
  private mounted() {
    window.scrollTo(0, 0);
  }
}
